import { Helmet } from "react-helmet-async";
// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";
import Container from "Components/Container/Container";

import { Link } from "react-router-dom";

import SuccessStoriesHeader from "./Components/SuccessStoriesHeader";

const SuccessStories = () => {
  const { data, error, loading } = useFetch("success-story");

  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  if (data) {
    const meta = data?.meta;
    return (
      <main className="">
        <Helmet>
          <title>{meta?.meta_title}</title>
          <meta name="description" content={meta?.meta_description} />
          <meta name="keywords" content={meta?.meta_keywords} />
        </Helmet>
        <SuccessStoriesHeader />
        <Container>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 my-secondary lg:my-primary">
            {data?.otherSuccessStories?.map(
              ({ image, mainTitle, slug }, index) => (
                <Link to={`/successStories/${slug}`} key={index}>
                  <img className="h-[200px] w-full" src={image} alt="" />
                  <p className="text-lg mt-2 px-2 font-medium">{mainTitle}</p>
                </Link>
              )
            )}
          </div>
        </Container>
      </main>
    );
  }
};

export default SuccessStories;
