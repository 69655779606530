// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";
import { Helmet } from "react-helmet-async";
// inner components
import Hero from "./Sections/Hero";
import AboutLiveRoute from "./Sections/AboutLiveRoute";
import Values from "./Sections/Values";
import AboutMidis from "./Sections/AboutMidis";
import Services from "Pages/Home/Sections/Services/Services";
const About = () => {
  const { data, loading, error } = useFetch("about-us");

  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  if (data) {
    const aboutData = data?.about;
    const heroData = aboutData?.hero[0];
    const aboutLiveRoute = aboutData?.about_liveRoute;
    const aboutMidis = aboutData?.about_midis;
    const values = aboutData?.values;
    const services = aboutData?.services;
    const meta = aboutData.meta;

    return (
      <main>
        <Helmet>
          <title>{meta?.meta_title}</title>
          <meta name="description" content={meta?.meta_description} />
          <meta name="keywords" content={meta?.meta_keywords} />
        </Helmet>
        <Hero data={heroData} />
        <AboutLiveRoute data={aboutLiveRoute} />

        <AboutMidis data={aboutMidis} valuesData={values} />

        <Values data={values} />

        <Services data={services} />
      </main>
    );
  }
};

export default About;
