import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
// UI
import Container from "Components/Container/Container";
// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";

// inner components
import Hero from "./Sections/Hero";
import OurPortfolio from "./Sections/OurPortfolio";
import SolutionSlider from "Components/Sliders/SolutionSlider";
const Solution = () => {
  const { slug } = useParams();
  const { data, loading, error, fetchData } = useFetch(`solutions/${slug}`);

  useEffect(() => {
    fetchData();
  }, [slug]);

  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  if (data) {
    const solutionData = data?.solutiondata;
    const solutionHeroData = solutionData?.hero;
    const solutionPortfolioData = solutionData?.our_portfolio;
    const sliderData = solutionData?.other_solution;
    const meta = solutionData?.meta;
    return (
      <main>
        {" "}
        <Helmet>
          <title>{meta?.meta_title}</title>
          <meta name="description" content={meta?.meta_description} />
          <meta name="keywords" content={meta?.meta_keywords} />
        </Helmet>
        <Hero data={solutionHeroData} />
        <OurPortfolio data={solutionPortfolioData} />
        <div className="bg-gray-200 py-secondary lg:py-primary">
          <Container>
            <h2 className="text-4xl text-center font-segu text-lightFont font-[700] mb-inner">
              Other Solutions
            </h2>
            <SolutionSlider data={sliderData} />
          </Container>
        </div>
      </main>
    );
  }
};

export default Solution;
