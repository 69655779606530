import { Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// layouts
import Header from "Layout/Header/Header";
import Footer from "Layout/Footer/Footer";
// pages
import NotFound from "Pages/NotFound.jsx";
import Home from "Pages/Home/Home";
import About from "Pages/About/About";
import IndustrySolution from "Pages/IndustrySolution/IndustrySolution";
import Contact from "Pages/Contact/Contact";
import Partners from "Pages/Partners/Partners";
import ProActvie from "Pages/ProActive/ProActvie";
import Services from "Pages/Services/Services";
import Solution from "Pages/Solution/Solution";
import News from "Pages/News/News";
import NewsDetailed from "Pages/News/NewsDetailed/NewsDetailed";
import Blog from "Pages/Blog/Blog";
import BlogDetailed from "Pages/Blog/BlogDetailed/BlogDetailed";
import SuccessStoriesDetailes from "Pages/SuccessStories/SuccessStoriesDetailes";
import SuccessStories from "Pages/SuccessStories/SuccessStories";
import Security from "Pages/Security/Security";
import ScrollToTop from "Helpers/ScrollToTop";

const App = () => {
  return (
    <div className="App">
      <HelmetProvider>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="solutions/:slug" element={<Solution />} />

          <Route path="partners" element={<Partners />} />
          <Route path="proactive" element={<ProActvie />} />
          <Route path="industry_solution" element={<IndustrySolution />} />
          <Route path="services/:slug" element={<Services />} />
          <Route
            path="successStories/:slug"
            element={<SuccessStoriesDetailes />}
          />
          <Route path="successStories" element={<SuccessStories />} />
          <Route path="security" element={<Security />} />
          <Route path="news" element={<News />} />
          <Route path="news/:slug" element={<NewsDetailed />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:slug" element={<BlogDetailed />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </HelmetProvider>
    </div>
  );
};

export default App;
