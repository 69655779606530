import useFetch from "Hooks/useFetch";
const HeaderLinks = () => {
  const {
    data: solutionData,
    loading: solutionLoading,
    error: solutionError,
  } = useFetch("solutions");

  const {
    data: serviceData,
    loading: serviceLoading,
    error: serviceError,
  } = useFetch("services");

  return [
    {
      id: 2,

      name: "Company",
      mega: true,
      title: "The Company",
      list: [
        {
          name: "About",
          url: "/about",
        },
        {
          name: "Contact",
          url: "/contact",
        },
        {
          name: "Partners",
          url: "/partners",
        },
        {
          name: "Careers",
          link: "https://www.midisgroup.com/careers/",
        },
        {
          name: "Security & Privacy",
          url: "/security",
        },
      ],
    },

    {
      id: 1,
      name: "Solutions",
      title: "Our Solutions",
      mega: true,
      list: solutionData?.solutiondata
        ?.map(({ title, slug }) => ({
          name: title,
          url: `/solutions/${slug}`,
        }))
        .reverse(), // Reverse the array here
    },

    {
      id: 3,
      name: "Services",
      title: "Our Services",
      mega: true,
      list: serviceData?.servicedata?.map(({ title, slug }) => ({
        name: title,
        url: `/services/${slug}`,
      })),
    },
    {
      name: "Industry solutions",
      url: "/industry_solution",
    },
    {
      name: " Success Stories",
      url: "/successStories",
    },
    // {
    //   name: "Resources",
    //   mega: true,
    //   id: 4,
    //   title: "Our Media",
    //   list: [
    //     {
    //       name: "Blog",
    //       url: "/blog",
    //     },
    //     {
    //       name: "News / Events",
    //       url: "/news",
    //     },
    //   ],
    // },

    // {
    //   name: "LiveRoute Proactive",
    //   url: "/proactive",
    // },
  ];
};

export default HeaderLinks;
