import Container from "Components/Container/Container";
import { Link } from "react-router-dom";
// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";

const Blog = () => {
  const { data, error, loading } = useFetch("blogs");

  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  if (data) {
    const blogData = data?.BlogsData;
    return (
      <main>
        <section className="pt-36 pb-12 lg:pt-40 lg:pb-16 bg-blue text-white">
          <Container>
            <h2 className="text-4xl font-segu">Our Blog</h2>
          </Container>
        </section>

        <Container>
          <section className="grid grid-cols-1 md:grid-cols-2 gap-14 lg:gap-6 my-secondary lg:my-primary">
            {blogData.map(({ name, slug, date, image }, index) => {
              return (
                <Link className="group" to={`${slug}`} key={index}>
                  <div>
                    <div className="overflow-hidden">
                      <img
                        src={image}
                        alt=""
                        className="h-[350px] w-full transition ease-in duration-300 group-hover:scale-[1.05]"
                      />
                    </div>
                    <div>
                      <span className="text-[#b8b8b8] my-2 block">{date}</span>
                      <p className="text-2xl font-semibold text-blueFont capitalize group-hover:underline">
                        {name}
                      </p>
                    </div>
                  </div>
                </Link>
              );
            })}
          </section>
        </Container>
      </main>
    );
  }
  return <main></main>;
};

export default Blog;
