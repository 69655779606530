import Hero from "./Sections/Hero";
import PartnersLanding from "./Sections/PartnersLanding";
// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";
import { Helmet } from "react-helmet-async";

const Partners = () => {
  const { data, loading, error } = useFetch("partners");
  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  if (data) {
    const heroData = data?.hero;
    const partnersListData = data?.list;
    const meta = data?.meta;
    return (
      <main>
        <Helmet>
          <title>{meta?.meta_title}</title>
          <meta name="description" content={meta?.meta_description} />
          <meta name="keywords" content={meta?.meta_keywords} />
        </Helmet>
        <Hero data={heroData} />
        <PartnersLanding data={partnersListData} />
        {/* <OtherPartnersSlider data={otherPartnersData} /> */}
      </main>
    );
  }
};

export default Partners;
