import React from "react";
import { Helmet } from "react-helmet-async";
// inner components
import Hero from "./Sections/Hero/Hero";
import Features from "./Sections/Features/Features";
import Services from "./Sections/Services/Services";
import PartnerSlider from "./Sections/Partner/PartnerSlider";
import Solution from "./Sections/Solution/Solution";
import About from "./Sections/About/About";
import LinkedIn from "./Sections/LinkedIn/LinkedIn";

// fetching
import useFetch from "Hooks/useFetch";
import IsLoading from "Components/RequestHandler/IsLoading";
import IsError from "Components/RequestHandler/IsError";

const Home = () => {
  const { data, error, loading } = useFetch("home");
  if (loading) return <IsLoading />;
  else if (error) return <IsError />;
  if (data) {
    const homeData = data?.homeData;
    const heroData = homeData?.hero;
    const aboutData = homeData?.aboutUs;
    const featureData = homeData.features;
    const servicesData = homeData?.services;
    const partnerData = homeData?.ourPartners;
    const solutionData = homeData?.solutions;
    const meta = data?.homeData?.meta;

    return (
      <main>
        <Helmet>
          <title>{meta?.meta_title}</title>
          <meta name="description" content={meta?.meta_description} />
          <meta name="keywords" content={meta?.meta_keywords} />
        </Helmet>
        <Hero data={heroData} />
        <Features data={featureData} />
        <LinkedIn />
        <Services data={servicesData} />
        <PartnerSlider data={partnerData} />
        <Solution data={solutionData} />
        <About data={aboutData} />
        {/* <MediaSlider /> */}
      </main>
    );
  }
};

export default Home;
